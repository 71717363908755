import { useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { useField } from '@unform/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type TVTextFieldProps = TextFieldProps & {
  name: string; 
  isDisabled?: boolean
}
export const VTextFieldPassword: React.FC<TVTextFieldProps> = ({ isDisabled, name, ...rest }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [value, setValue] = useState(defaultValue || '');
  const [tipo, setTipo] = useState('password');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if(showPassword === true){
      setTipo('password');
    }else{
      setTipo('text');
    }
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    });
  }, [registerField, fieldName, value]);

  return (
    <TextField
      {...rest}
      
      error={!!error}
      helperText={error}
      defaultValue={defaultValue}
      
      type={tipo}
      disabled={isDisabled}
      value={value}
      onChange={e => { setValue(e.target.value); rest.onChange?.(e); }}
      onKeyDown={(e) => { error && clearError(); rest.onKeyDown?.(e); }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" disablePointerEvents={isDisabled}>
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}; 