import { createContext, useCallback, useContext, useState } from 'react';

interface IDrawerOption {
  icon: string;
  path: string;
  label: string;
}
interface IDrawerItemOption{
  icon: string;
  path: string;
  label: string;
  parent: string;
}

interface IDrawerContextData {
  isDrawerOpen: boolean;
  toggleDrawerOpen: () => void;
  drawerOptions: IDrawerOption[];
  drawerOptionsr: IDrawerOption[];
  drawerItemOptions: IDrawerItemOption[];
  setDrawerOptions: (newDrawerOptions: IDrawerOption[]) => void;
  setDrawerOptionsr: (newDrawerOptionsr: IDrawerOption[]) => void;
  setDrawerItemOptions: (newDrawerItemOptions: IDrawerItemOption[]) => void;
  setMenu : (newDrawerOptions: IDrawerOption[])  => void;
  setMenuR : (newDrawerOptions: IDrawerOption[])  => void;
  menu: IDrawerOption[];
  menur: IDrawerOption[];
}

interface IDrawerProviderProps {
  children: React.ReactNode
}

const DrawerContext = createContext({} as IDrawerContextData);

export const useDrawerContext = () => {
  return useContext(DrawerContext);
};

export const DrawerProvider: React.FC<IDrawerProviderProps> = ({ children })  => {
  const [drawerOptions, setDrawerOptions] = useState<IDrawerOption[]>([]);
  const [drawerOptionsr, setDrawerOptionsr] = useState<IDrawerOption[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [ drawerItemOptions, setDrawerItemOptions] = useState<IDrawerItemOption[]>([]);
  const [menu, setMenu] = useState<IDrawerOption[]>([]);
  const [menur, setMenuR] = useState<IDrawerOption[]>([]);

  const toggleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(oldDrawerOpen => !oldDrawerOpen);
  }, []);

  const handleSetDrawerOptions = useCallback((newDrawerOptions: IDrawerOption[]) => {
    setDrawerOptions(newDrawerOptions);
  }, []);

  const handleSetDrawerOptionsr = useCallback((newDrawerOptionsr: IDrawerOption[]) => {
    setDrawerOptionsr(newDrawerOptionsr);
  }, []);

  const handleSetMenu = useCallback((newDrawerOptions: IDrawerOption[]) => {
    setDrawerOptions(newDrawerOptions);
    setMenu(newDrawerOptions);
  }, []);
  const handleSetDrawerItemOptions = useCallback((newDrawerItemOptions: IDrawerItemOption[])=>{
    setDrawerItemOptions(newDrawerItemOptions);
},[]);

  const handleSetMenuR = useCallback((newDrawerOptionsr: IDrawerOption[]) => {
    setDrawerOptionsr(newDrawerOptionsr);
    setMenuR(newDrawerOptionsr);
  }, []);

  return (
    <DrawerContext.Provider value={{ drawerItemOptions,isDrawerOpen, drawerOptions, drawerOptionsr, menu, menur, 
      toggleDrawerOpen, setDrawerOptions: handleSetDrawerOptions, setDrawerOptionsr: handleSetDrawerOptionsr, setMenu : handleSetMenu, setMenuR : handleSetMenuR, setDrawerItemOptions: handleSetDrawerItemOptions}}>
      {children}
    </DrawerContext.Provider>
  );
};