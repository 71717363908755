import { Api } from '../../axios-config';

export interface IDetalheHoraExtra {
  idfun: string;
  datalan: Date;
}

const create = async (dados: IDetalheHoraExtra): Promise<void | Error> => {
  try {
    await Api.post('/pontosbomba', dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const BombaService = {
  create,
};