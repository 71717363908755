
import { useRef, useState, useCallback, useEffect } from 'react';
import { Box, Grid, LinearProgress, Paper, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { Abateciemento } from '../../../shared/services/api/estoquefazenda/abastecimento/abastecimentoservice';
import { VForm, useVForm, AutoCompleteCultivo, AutoCompleteAtividades, VTextField, VTextFieldData, IVFormErrors, VTextFieldNumber, AutoCompletePivotfazenda, AutoCompleteProduto2 } from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { PatrimonioService } from '../../../shared/services/api/estoquefazenda/patrimonioservice/patrimonioservice';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import SwipeableTextMobileStepper from '../../../shared/forms/SwipeableTextMobileStepper';
import { Environment } from '../../../shared/environment';
import { SaidaService } from '../../../shared/services/api/estoquefazenda/saida/SaidaService';

interface IImage {
  imgPath: string;
}
interface IFormData {
  idpatrimonio: string,
  idprod: string,
  dataoperacao: Date,
  idcultivo?: string | null;
  idpivot?: string | null;
  idatividade: string,
  horimetro: number,
  horimetronicial: number,
  quantidade: number,
  preco: number,
  total: number,
  operador: string,
  horastrabalhadas: number,
  litros: number,
  kmhr: string,
  quantidadeestoque: number,
}
const formValidationSchema: yup.ObjectSchema<Omit<IFormData, 'idoperacao' | 'totalabastecimento'>> = yup.object().shape({
  idpatrimonio: yup.string().required(),
  idprod: yup.string().required(),
  dataoperacao: yup.date().required('O campo é obrigatório').test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();

    if (!value || value > currentDate) return false;
    if ((value.getTime() < currentDate.getTime() - (10 * 86400000))) return false;

    return true;
  }),
  idcultivo: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idpivot: yup.string().nullable().matches(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/, 'Deve ser um UUID válido'),
  idatividade: yup.string().required(),
  horimetronicial: yup.number().optional().default(0),
  horimetro: yup.number().required('Horímetro Atual está incorreto!')
    .test('is-valid-horastrabalhadas', 'Algo de errado no preenchimento do campo Horímetro/KM Atual', function (value) {
      const { kmhr, horimetronicial } = this.parent;
      if (kmhr === 'km') {
        return value > horimetronicial;
      }
      return true;
    }),
    quantidade: yup.number().required('Quantidade inválida!')
    .test('is-valid-quantidade', 'Quantidade maior que o tanque / Quantidade menor que o Estoque', function (value) {
      const { quantidadeestoque, litros } = this.parent;
      if (quantidadeestoque != null && litros != null) {
        return value > 0 && value <= quantidadeestoque && value <= litros;
      }
      return false;
    }),
  preco: yup.number().required().moreThan(0),
  total: yup.number().required().moreThan(0),
  operador: yup.string().required(),
  horastrabalhadas: yup.number()
    .required('Horímetro Atual está incorreto!')
    .test('is-valid-horastrabalhadas', 'Algo de errado no preenchimento do campo Horímetro/KM Atual', function (value) {
      const { kmhr } = this.parent;
      if (kmhr === 'km') {
        return value < 700;
      } else if (kmhr === 'horimetro') {
        return value > 0 && value <= 24;
      }
      return true;
    }),
  kmhr: yup.string().required().default(''),
  litros: yup.number().required().moreThan(0),
  quantidadeestoque: yup.number().required(),
});
export const DetalheAbastecimento: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const patrimonioref = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [patrimonio, setpatrimonio] = useState('');
  const [descricao, setdescricao] = useState('');
  const [modelo, setmodelo] = useState('');
  const [marca, setmarca] = useState('');
  const [horimetroinicial, sethorimetroinicial] = useState(0);
  const [presosaidaa, setprecosaida] = useState(0);
  const [idprod, setidprod] = useState<string>();
  const [totalvalor, settotalvalor] = useState(0);
  const [quantidadeestoque, setquantidadeestoque] = useState(0);
  const [litros, setlitros] = useState(0);
  const [kmhora, setkmhr] = useState('');
  const [idpatrimonios, setidpatrimonios] = useState('');
  const [images, setImages] = useState<IImage[]>([{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.png` }]);
  const imagesnovo = [{ imgPath: `${Environment.URL_IMGPATRIMONIO}semimagem.png`, arquivo: undefined }];
  useEffect(() => {
    patrimonioref.current?.focus();
  }, [formRef]);
  const dataFormatada = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate());
    if (dataAtual.getDay() === 0) {
      dataAtual.setDate(dataAtual.getDate());
    }
    return dataAtual.toISOString().split('T')[0];
  };
  const handleprecosaida = (psaida: number | undefined) => {
    if (psaida != null) {
      setprecosaida(psaida);
    }
  };
  const novoLancamento2 = () => {
    formRef.current?.setData({
      idprod: '',
      dataoperacao: dataFormatada(),
      idcultivo: null,
      idpivot: null,
      idatividade: '',
      horimetro: 0,
      horastrabalhadas: 0,
      operador: '',
      quantidade: 0,
    });
    sethorimetroinicial(0);
    setdescricao('');
    setmodelo('');
    setmarca('');
  }
  const novoLancamento = () => {
    formRef.current?.setData({

      idprod: '',
      dataoperacao: dataFormatada(),
      idcultivo: null,
      idpivot: null,
      idatividade: '',
      horimetro: 0,
      horastrabalhadas: 0,
      operador: '',
      quantidade: 0,
    });
    sethorimetroinicial(0);
    setpatrimonio('');
    setdescricao('');
    setmodelo('');
    setmarca('');
    setImages(imagesnovo);
  }

  useEffect(() => {
    if(idprod != null ){
        SaidaService
    .estoqueQuantidadeFaz({ idprod, vencimento: false, validade: null })
    .then((result) => {
     if (result instanceof Error) {
        alert('erro em quantidade')
     } else {
      setquantidadeestoque(result.quantidade);
      console.log('resultado quantidade'+result.quantidade)
     }
     });
    }
  
   }, [idprod]);

 

  const handlepreco = (valor: string | undefined) => {
    const preco = (presosaidaa);
    const quantidadevalor = (valor);
    if ((preco)) {
      const total = (Number(preco) * Number(quantidadevalor));
      settotalvalor(total);
    } else {
      alert('campo de combustivel não selecionado corretamente.');
    }
  };
  const handletotal = (valor: string | undefined) => {
    if (valor != null) {
      const valorinicial = horimetroinicial;
      const tvalor = (Number(valor) - Number(valorinicial));
      formRef.current?.setFieldValue('horastrabalhadas', tvalor);
    }
  };
  const handlebusca = useCallback(async (patrimonio: any) => {
    novoLancamento2();
    setIsLoading(true);
    if (patrimonio) {
      try {
        const result = await PatrimonioService.getAll(patrimonio);
          if(result.veiculos === true){
             setidpatrimonios(result.idpatrimonio);
             setdescricao(result.descricao);
             setmodelo(result.modelo);
             setmarca(result.marca);
             sethorimetroinicial(result.horimetro);
             setlitros(result.litros);
             setkmhr(result.tipomedicao);

              if (result.fotos === null || result.fotos.length === 0) {
                 setImages(imagesnovo);
              } else {
                 const imageList: IImage[] = [];
                  result.fotos.forEach((item: { imgPath: IImage[]; }) => {
                 imageList.push({ imgPath: Environment.URL_IMGPATRIMONIO! + item.imgPath });
               });
             setImages(imageList);
              }


        setTimeout(() => {
          patrimonioref.current?.focus();
        }, 0);

        setIsLoading(false);
          }else{
            alert('Patrimonio não e um Veiculo')
            setIsLoading(false);
            novoLancamento()
          }
       
      } catch (error: any) {
        setIsLoading(false);
        alert('Erro no código: ' + error.message);
        setTimeout(() => {
          patrimonioref.current?.focus();
        }, 0);
      }
    }
  }, [patrimonio]);
  const handleSave = (dados: IFormData) => {
    const dadosAtualizados = { ...dados, horimetronicial: horimetroinicial, idpatrimonio: idpatrimonios, total: totalvalor, preco: presosaidaa, kmhr: kmhora, litros: litros, quantidadeestoque: quantidadeestoque,};
    formValidationSchema
      .validate(dadosAtualizados, { abortEarly: false })
      .then((result) => {
        setIsLoading(true);
        if (id === 'novo') {
          Abateciemento
            .create(result)
            .then((result) => {
              setIsLoading(false)
              if (result instanceof Error) {
                alert(result.message);
                novoLancamento()
                setTimeout(() => {
                  patrimonioref.current?.focus();
                }, 0);
              } else {
                novoLancamento();
                setTimeout(() => {
                  patrimonioref.current?.focus();
                }, 0);
              }
            });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      })
  };
  return (
    <LayoutBaseDePagina
      titulo={'Abastecimento'}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoNovo={false}
          mostrarBotaoVoltar={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao={false}
          mostrarBotaoSalvar
          aoClicarEmSalvar={save}
          mostrarBotaoSalvarCarregando={isLoading}
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder="Seu Placeholder Aqui"onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={1}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <SwipeableTextMobileStepper
                  images={images}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container item direction="row" spacing={1} >
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                      fullWidth
                      size='small'
                      value={patrimonio}
                      label='Patrimonio'
                      inputRef={patrimonioref}
                      disabled={isLoading}
                      onChange={e => setpatrimonio(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && patrimonio.trim() !== '') {
                          handlebusca(patrimonio);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                    <TextField
                      fullWidth
                      size='small'
                      value={descricao}
                      label='Descrição'
                      disabled={true}
                    />
                  </Grid>
                 <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <TextField
                      fullWidth
                      size='small'
                      value={marca}
                      label='Marca'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                    <TextField
                      fullWidth
                      size='small'
                      value={modelo}
                      label='Modelo'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <VTextFieldData
                      fullWidth
                      name='dataoperacao'
                      label='Data de Abastecimento'
                      disabled={isLoading}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <AutoCompleteProduto2
                      Label='Tipo Combustível'
                      combustivel='true'
                      precoSaida={handleprecosaida}
                      idproduto={setidprod}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={9} lg={9} xl={9}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='operador'
                      label='Operador'
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <TextField
                      fullWidth
                      size='small'
                      value={horimetroinicial}
                      label='Horímetro/KM Inicial'
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='horimetro'
                      label='Horímetro/KM Atual'
                      calcular={handletotal}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <VTextField
                      fullWidth
                      size='small'
                      name='horastrabalhadas'
                      label='Horas Trabalhadas/ KM '
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <VTextFieldNumber
                      fullWidth
                      size='small'
                      name='quantidade'
                      label='Quantidade'
                      calcular={handlepreco}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <AutoCompletePivotfazenda />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <AutoCompleteCultivo />
                  </Grid>
                  <Grid item xs={4} sm={4} md={6} lg={6} xl={6}>
                    <AutoCompleteAtividades
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm>
    </LayoutBaseDePagina>
  );
};