
import { v4 as uuidv4 } from 'uuid';
import { Environment } from '../../../environment';
import { Apicadastro } from '../axioscadastro';

export interface ICultivos {
    idcultivo: string;
    descricao: string;
    status: boolean;
    urlimg: string;
}

export interface IDetalhesCultivos {
  descricao: string;
  status: boolean;
}
export interface IUpdateImg {
  urlimg: string;
}

type TComTotalCount = {
    data: ICultivos[];
    totalCount: number;
}

const getAll = async (page = 1, filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa = `/cultivos?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllist = async (filter = ''): Promise<TComTotalCount | Error> => {
  try {
    const urlRelativa =  `/cultivoslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<ICultivos | Error> => {
  try {
    const { data } = await Apicadastro.get(`/cultivos/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (image: File | undefined,  dados: Omit<IDetalhesCultivos, 'status'>): Promise<string | Error> => {
  try {

    const formData = new FormData();
    formData.append('idcultivo', uuidv4());
       
    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

    formData.append('file', image || ''); 

    const { data } = await Apicadastro.post<IDetalhesCultivos>('/cultivos', formData);

    if (Object.keys(data).length === 0) {
      return 'registro ciado com sucesso! ';
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalhesCultivos): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/cultivos/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const updateByIdImg = async (id: string, image: File | undefined, urlimg: string | null): Promise<void | Error> => {
  try {
  
    const dados = {urlimg: urlimg};
    
    const formData = new FormData();
     
    Object.keys(dados).forEach((key) => {
      formData.append(key, (dados as any)[key]);
    });

    formData.append('file', image || ''); 

    await Apicadastro.put(`/cultivosupdateimg/${id}`, formData);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/cultivos/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const CultivosService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
  updateByIdImg,
};