import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';

interface NavTabsProps {
  case: number;
}

export default function NavTabsListagensDePonto({ case: initialCase = 0 }: NavTabsProps) {

  const [value, setValue] = React.useState(initialCase);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    // Aqui você pode adicionar lógica para redirecionar com base no valor da guia
    switch (newValue) {
    case 0:
      navigate('/pontosrdiario');
      break;
    case 1:
      navigate('/pontosrmes');
      break;
    default:
      break;
    }
  };
  
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Listagem Diária" />
        <Tab label="Listagem Mensal" />
      </Tabs>
    </Box>
  );
}
