import { Api } from '../../axios-config';

export interface IDetalhefuncionarioponto  {
  idfun: string;
  nome: string;
  urlimg: string;
}

const getAll = async (filter = ''): Promise<IDetalhefuncionarioponto> => {
  try {
    const urlRelativa = `/funcionariosponto/${filter}`;
    const { data } = await Api.get(urlRelativa);
    if (data) {
      return data;
    } else {
      throw new Error('Nenhum dado retornado. Código incorreto.');
    }
  } catch (error) {
    console.error('Erro ao obter dados:', error);
    throw new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const Funcionariosponto = {
  getAll,
};