import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { FerramentasDaListagemSaida } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemSaida';
import { IListagemTransferencias, TransferenciaService } from '../../../shared/services/api/estoquefazenda/transferencia/TransferenciaService';

export const ListagemDeTransferencias: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemTransferencias[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  
  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'data'; 
  }, [searchParams]);

  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  useEffect(() => {
    if (tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal)) { 

      setIsLoading(true);
   
      debounce(() => {
        TransferenciaService.getAll(pagina, dataInicial, dataFinal, tipo)
          .then((result) => {
            setIsLoading(false);
  
            if (result instanceof Error) {
              alert(result.message);
            } else {
              setTotalCount(result.totalCount);
              setRows(result.data);
            }
          });
      });
    }     
    
  }, [dataInicial, dataFinal, pagina, tipo]);
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Transferências'
      barraDeFerramentas={
        <FerramentasDaListagemSaida
          mostrarInputData
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/transferenciaestoque/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarDataFinal={datafinal => setSearchParams({ inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Origem</TableCell>
              <TableCell>Destino</TableCell>
              <TableCell>Portador</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idtransferencia} 
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/transferenciaestoque/detalhe/${row.idtransferencia}`)}
              >
                <TableCell>{dayjs(row.datalan).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.origem}</TableCell>
                <TableCell>{row.destino}</TableCell>
                <TableCell>{row.portador}</TableCell>
                <TableCell>{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};