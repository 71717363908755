import axios from 'axios';

import { responseInterceptor, errorInterceptor } from './interceptors';
import { Environment } from '../../../../environment';

const tokenFromLocalStorage = localStorage.getItem('APP_ACCESS_TOKEN');
const authorizationHeader = tokenFromLocalStorage ? `Bearer ${JSON.parse(tokenFromLocalStorage)}` : '';

const Apiestoque = axios.create({
  baseURL: Environment.URL_BASEESTOQUE,
  headers: {
    Authorization: authorizationHeader
  }
});

Apiestoque.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
);

export { Apiestoque };