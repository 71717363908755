
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, IVFormErrors, VTextFieldData, AutoCompleteFuncionario,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { DomingoService } from '../../../shared/services/api/fazenda/domingo/DomingoService';


interface IFormData {
  idfun: string;
  datalan: Date;
}


const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  datalan: yup.date().required('O campo é obrigatório')
  .test('valid-date', 'Selecione uma data válida', function (value) {
    const domingo = new Date();
    const diasParaDomingo = domingo.getDay(); 
    domingo.setDate(domingo.getDate() - diasParaDomingo);

    if(!value || value.toISOString().split('T')[0] !== domingo.toISOString().split('T')[0]) return false;
    
    return true;
  }),
});
export const Domingo: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');

  const dataFormatada = () => {
    const dataAtual = new Date();
    const diasParaDomingo = dataAtual.getDay(); 
    dataAtual.setDate(dataAtual.getDate() - diasParaDomingo);
    return dataAtual.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        datalan: dataFormatada(),
      });
      setUrlimg('');
    }
  }, [id]); 

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      datalan: dataFormatada(),
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          DomingoService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else{
                alert('Domingo salvo com sucesso!');
                novoLancamento();
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Domingo' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>          
                <VTextFieldData
                  fullWidth
                  name='datalan'
                  disabled={true}
                  label='Data'
                />
              </Grid>
              </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Box>
      </VForm> 
    </LayoutBaseDePagina>
  );
};