import { Apiestoque } from "../../estoque/axiosestoque";

export interface ILocalizacao {
  idprod: string;
  descricao: string;
  referencia: string;
  qtdestoque: number;
}

export interface IVencimentos {
  idprod: string;
  descricao: string;
  referencia: string;
  validade: Date;
  dias: number;
  qtdestoque: number;
}

export interface IOperacao{
  idoperacoes: string;
  idfazenda: string;
  idcultivo: string;
  datainicial: Date;
  datafinal: Date;
  detalhes: boolean;
}

interface Item {
  iditens: string;
  descricao: string;
  validade: Date;
  referencia: string;
  localizacao: string;
  qtdsaida: number;
  preco: number;  
  total: number;
}

interface Saida {
  idsaida: string;
  datalan: string;
  cultivo: string;
  fazenda: string;
  parceiro: string;
  total: number;
}

interface Entry {
  saida: Saida;
  itens: Item[];
}

const Localizacao = async (localizacao = '', tipo = '', grupo = '', subgrupo = ''): Promise<ILocalizacao[] | Error> => {
  try {
    const urlRelativa = `/rlocalizacao?tipo=${tipo}&localizacao=${localizacao}&grupo=${grupo}&subgrupo=${subgrupo}`;
  
    const { data } = await Apiestoque.get(urlRelativa);
  
    if (data) {
      return data;
    }
  
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const Vencimentos = async (localizacao = '', tipo = '', dias = ''): Promise<IVencimentos[] | Error> => {
  try {
    const urlRelativa = `/restoquevencimentos?tipo=${tipo}&localizacao=${localizacao}&dias=${dias}`;
  
    const { data } = await Apiestoque.get(urlRelativa);
  
    if (data) {
      return data;
    }
  
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const Operacoes = async (dados: IOperacao): Promise<Entry[] | Error> => {
  try {

    const { data } = await Apiestoque.post<Entry[]>('/restoqueoperacoes', dados);
    
    if (data) {
      return data;
    }
    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};
 
export const RelatorioEstoqueService = {
  Localizacao,
  Vencimentos,
  Operacoes,
}; 