import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, IVFormErrors, AutoCompleteFuncionario, BasicSelect,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { AlimentacaoService } from '../../../shared/services/api/fazenda/alimentacao/AlimentacaoService';

interface IFormData {
  idfun: string;
  tipo: string;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  tipo: yup.string().required(),
});

export const Alimentacao: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  
  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        tipo: '',
      });
      setUrlimg('');
    }
  }, [id]); 

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      tipo: '',
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          AlimentacaoService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else{
                alert('Alimentação salvo com sucesso!');
                novoLancamento();
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Alimentação' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={9} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={12} lg={12} xl={12}>          
                <BasicSelect
                  data={[
                    { nameBD: 'C', descricao: 'Café' },
                    { nameBD: 'A', descricao: 'Almoço' },
                    { nameBD: 'J', descricao: 'Janta' },
                  ]}
                  label='Tipo'
                  name='tipo'
                  disabled={isLoading}
                />
              </Grid>
              </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};