
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VTextField,  VForm, useVForm, IVFormErrors, VTextFieldData, VTextFieldNumber, AutoCompleteFuncionario,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { HoraExtraService } from '../../../shared/services/api/fazenda/horaExtra/HoraExtraService';
import { Environment } from '../../../shared/environment';

interface IFormData {
  idfun: string;
  historico: string;
  datalan: Date;
  horaextra: number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  historico: yup.string().optional().default(''),
  datalan: yup.date().required('O campo é obrigatório')
  .test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();

    if (!value || value > currentDate) return false; 
    if ((currentDate.getDay() === 1 && value.getTime() < currentDate.getTime() - (3*86400000)) || (currentDate.getDay() !== 1 && value.getTime() < currentDate.getTime() - (2*86400000))) return false; 
      
    return true;
  }),
  horaextra: yup.number().transform((value, originalValue) => (originalValue === '' ? undefined : value)).required('O campo é obrigatório').typeError('O campo deve ser um número válido').max(10, 'O valor não pode ser maior que 10').moreThan(0),
});


export const HoraExtra: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');

  const dataFormatada = () => {
    const dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() - 1);
    if (dataAtual.getDay() === 0) {
      dataAtual.setDate(dataAtual.getDate() - 1);
    }
    // Formata a data como string no formato 'yyyy-mm-dd'
    return dataAtual.toISOString().split('T')[0];
  };
  
  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        historico: '',
        datalan: dataFormatada(),
        horaextra: 1,
      });
      setUrlimg('');
    }
  }, [id]); 

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      historico: '',
      datalan: dataFormatada(),
      horaextra: 1,
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          HoraExtraService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else{
                alert('Hora Extra salva com sucesso!');
                novoLancamento();
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach(error => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Hora Extra' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >
      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">

          <Grid container direction="column" padding={2} spacing={2}>

            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}

            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              
              <Grid item xs={12} sm={12} md={9} lg={6} xl={4}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>          
                <VTextField
                  fullWidth
                   size='small'
                  name='historico'
                  disabled={isLoading}
                  label='Serviço Prestado'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>          
                <VTextFieldData
                  fullWidth
                  name='datalan'
                  disabled={isLoading}
                  label='Data'
                />
              </Grid>
              <Grid item xs={3} sm={3} md={8} lg={8} xl={8}>          
                <VTextFieldNumber
                  fullWidth
                   size='small'
                  name='horaextra'
                  disabled={isLoading}
                  label='Hora Extra'
                />
              </Grid>
              </Grid>
          </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};