import { Apicadastro } from '../../axioscadastro';

export interface IDetalhePatrimonio {
  idpatrimonio: string;
  descricao: string;
  modelo: string;
  marca: string;
  horimetro: number;
  veiculos: boolean;
  litros: number;
  tipomedicao: string;
  fotos: [];
}

const getAll = async (numero = ''): Promise<IDetalhePatrimonio> => {
  try {
    const urlRelativa = `/patrimonionumero?numero=${numero}`;

    const { data } = await Apicadastro.get(urlRelativa);

    if (data) {
      return data;

    } else {
      throw new Error('Nenhum dado retornado. Código incorreto.');
    }
  } catch (error) {
    console.error('Erro ao obter dados:', error);
    throw new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const PatrimonioService = {
  getAll,
};