import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemRdiario, IListagemRdiarioTotais, RdiarioService } from '../../../shared/services/api/fazenda/rdiario.mes/RdiarioService';
import NavTabsListagensDePonto from './NavTabsListagensDePonto';
import OutlinedCard from '../../../shared/forms/OutlinedCard';
import { FerramentasDaListagemRdiario } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemRdiario';
import dayjs from 'dayjs';

export const ListagemRDiario: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const [rows, setRows] = useState<IListagemRdiario[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [resumoPontoDiario, setResumoPontoDiario] = useState<IListagemRdiarioTotais>({
    presenca: 0,
    falta: 0,
    atestado: 0,
    horaextra: 0,
    pistola: 0,
    bomba: 0,
    cafe: 0,
    almoco: 0,
    janta: 0,
  });

  const busca = useMemo(() => {
    return searchParams.get('busca') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);
  
  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      RdiarioService.getAll(pagina, busca)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRows(result.data);
          }
        });
    });
  }, [busca, pagina]);

  useEffect(() => {
    RdiarioService.getById(busca)
      .then((result) => { 
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setResumoPontoDiario(result);
        }
      });
  }, [busca]);
    
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Ponto Diário'
      barraDeFerramentas={
        <FerramentasDaListagemRdiario
          mostrarBotaoNovo={false}
          mostrarInputBusca
          textoDaBusca={busca}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabsListagensDePonto
          case={0}  
        />
        <Box display="flex" component={Paper} variant="outlined">
          <Grid container item direction="row" >
            <Grid item xs={6} sm={6} md={4} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Presença" 
                valor={resumoPontoDiario.presenca} 
                cor="#6AF8B3"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Faltas" 
                valor={resumoPontoDiario.falta}
                cor="#FFAFA8"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Atestado" 
                valor={resumoPontoDiario.atestado}
                cor="#80C7FD"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Bomba" 
                valor={resumoPontoDiario.bomba}
                cor="#6AF8B3"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Pistola" 
                valor={resumoPontoDiario.pistola}
                cor="#FFAFA8"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.5} xl={1.5}>
              <OutlinedCard 
                nome="Hora Extra" 
                valor={resumoPontoDiario.horaextra} 
                cor="#80C7FD"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Café" 
                valor={resumoPontoDiario.cafe}
                cor="#6AF8B3"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Almoço" 
                valor={resumoPontoDiario.almoco}
                cor="#FFAFA8"
                loading={isLoading}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={2} lg={1.3} xl={1.3}>
              <OutlinedCard 
                nome="Janta" 
                valor={resumoPontoDiario.janta}
                cor="#80C7FD"
                loading={isLoading}
              />
            </Grid>
          </Grid>
        </Box>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Colaborador</TableCell>
              <TableCell align='center'>Ponto</TableCell>
              <TableCell align='center'>Bomba</TableCell>
              <TableCell align='center'>Pistola</TableCell>
              <TableCell align='center'>Café</TableCell>
              <TableCell align='center'>Almoço</TableCell>
              <TableCell align='center'>Janta</TableCell>
              <TableCell align='center'>Horas Extra</TableCell>
              <TableCell align='center'>Serviços Prestados</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow 
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{row.nome}</TableCell>
                <TableCell align='center'>{row.ponto}</TableCell>
                <TableCell align='center'>{row.bomba}</TableCell>
                <TableCell align='center'>{row.pistola}</TableCell>
                <TableCell align='center'>{row.cafe}</TableCell>
                <TableCell align='center'>{row.almoco}</TableCell>
                <TableCell align='center'>{row.janta}</TableCell>
                <TableCell align='center'>{row.horaextra}</TableCell>
                <TableCell align='center'>{row.historico}</TableCell>
              </TableRow>
            ))}
          </TableBody>
  
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
  
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={10}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};