import { Environment } from '../../../../environment';


import { Apiestoque } from '../../estoque/axiosestoque';

export interface IListagemAtividades {
    idatividade: string;
    descricao: string;
}



type TotalCount = {
    data: IListagemAtividades[];
    totalCount: number;
}

const getAlllist = async (filter = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa = `/atividadeslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;

    const { data, headers } = await Apiestoque.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};




export const AtividadesService = {

  getAlllist,
 
};