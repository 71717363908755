import { Apicadastro } from '../axioscadastro';


interface IAuth {
   accessToken: string;
}

export interface IUsuarios {
    usuario: string;
    senha: string;
}

const auth = async (usuario: string, senha: string): Promise<IAuth | Error> => {
  try {
     
    const dados = {
      usuario : usuario,
      senha : senha,
    };

    const { data } = await Apicadastro.post('/login', dados);

    if (data) {
      return data;
    }

    return new Error('Erro no login.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro no login.');
  }
};

export const AuthService = {
  auth,
};