
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, LinearProgress, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { VForm, useVForm, IVFormErrors, VTextFieldData, AutoCompleteFuncionario, SelectNumber,} from '../../../shared/forms';
import { FerramentasDeDetalhe } from '../../../shared/components';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { Environment } from '../../../shared/environment';
import { AtestadoService } from '../../../shared/services/api/fazenda/atestado/AtestadoService';

interface IFormData {
  idfun: string;
  datainicial: Date;
  quantidade: number;
}

const formValidationSchema: yup.ObjectSchema<IFormData> = yup.object().shape({
  idfun: yup.string().required(),
  datainicial: yup.date().required('O campo é obrigatório')
  .test('valid-date', 'Selecione uma data válida', function (value) {
    const currentDate = new Date();
    if (!value || value > currentDate || value.getMonth() !== currentDate.getMonth() || value.getFullYear() !== currentDate.getFullYear()) return false; 
    return true;
  }),
  quantidade: yup.number().required(),
});


export const Atestado: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [urlimg, setUrlimg] = useState('');
  
  useEffect(() => {
    if (id === 'novo') {
      formRef.current?.setData({
        idfun: '',
        datainicial: '',
        quantidade: '',
      });
      setUrlimg('');
    }
  }, [id]); 

  const handlefoto = (urlimg: string | undefined) => {
    if (urlimg != null) {
      setUrlimg(Environment.URL_IMGFUNCIONARIO+urlimg);
    }else{
      setUrlimg('/logo.png');
    }
  };

  const novoLancamento = () =>{
    formRef.current?.setData({
      idfun: '',
      datainicial: '',
      quantidade: '',
    });
    setUrlimg('');
  }

  const handleSave = (dados: IFormData) => {
    formValidationSchema.
      validate(dados, { abortEarly: false })
      .then((dadosValidados) => {
        setIsLoading(true);
        if (id === 'novo') {
          
          AtestadoService
            .create(dadosValidados)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              }else{
                alert('Atestado salvo com sucesso!');
                novoLancamento();
              }
            });
        } 
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};
        errors.inner.forEach(error => {
          if (!error.path) return;
          validationErrors[error.path] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <LayoutBaseDePagina
      titulo={id === 'novo' ? 'Atestado' : ''}
      barraDeFerramentas={
        <FerramentasDeDetalhe
          mostrarBotaoSalvar
          mostrarBotaoVoltar={false}
          mostrarBotaoSalvarEFechar={false}
          mostrarBotaoSalvarCarregando = {isLoading}
          mostrarBotaoSalvarEFecharCarregando = {isLoading}
          mostrarBotaoNovo={false}
          mostrarBotaoApagar={false}
          mostrarBotaoImpressao = {false}
          aoClicarEmSalvar={save} 
        />
      }
    >

      <VForm ref={formRef} onSubmit={handleSave} placeholder=""onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
        <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined">
          <Grid container direction="column" padding={2} spacing={2}>
            {isLoading && (
              <Grid item>
                <LinearProgress variant='indeterminate' />
              </Grid>
            )}
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={6} sm={6} md={3} lg={2} xl={1.5} >
                <Box width="100%" marginRight={5} >
                  <Avatar
                    variant='rounded'
                    alt='F'
                    src={urlimg}
                    sx={{ width: '100%', height: 200 }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6} xl={6}>
                <Grid container item direction="row" spacing={2}> 
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <AutoCompleteFuncionario 
                  isExternalLoading={id === 'novo' ? isLoading : true}
                  urlfoto={handlefoto}
                />
              </Grid> 
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>          
                <VTextFieldData
                  fullWidth
                  name='datainicial'
                  disabled={isLoading}
                  label='Data inicial'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>          
                <SelectNumber
                  data={[
                    { nameBD: 0.5, descricao: '0.5' },
                    { nameBD: 1, descricao: '1' },
                    { nameBD: 2, descricao: '2' },
                    { nameBD: 3, descricao: '3' },
                    { nameBD: 4, descricao: '4' },
                    { nameBD: 5, descricao: '5' },
                    { nameBD: 6, descricao: '6' },
                    { nameBD: 7, descricao: '7' },
                    { nameBD: 8, descricao: '8' },
                    { nameBD: 9, descricao: '9' },
                    { nameBD: 10, descricao: '10' },
                    { nameBD: 11, descricao: '11' },
                    { nameBD: 12, descricao: '12' },
                    { nameBD: 13, descricao: '13' },
                    { nameBD: 14, descricao: '14' },
                    { nameBD: 15, descricao: '15' },
                  ]}
                  label='Dias do atestado'
                  name='quantidade'
                  disabled={isLoading}
                />
              </Grid>
              </Grid>
          </Grid>
            </Grid>
          </Grid>
        </Box>
      </VForm> 

    </LayoutBaseDePagina>
  );
};