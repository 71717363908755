/*
Para usar o componente de Seleção Básica importe-o. No seu uso coloque na variável data o arrei de objetos que será usado na seleção, 
em nameBD fica o que será enviado ao banco de dados, já em descrição fica o que será apresentado ao usuário, na variável label fica o nome 
do input que apareçe para o usuário e na variável name fica a referencia da variável que receberá o valor da seleção, no exemplo abaixo 
o valor selecionado será armazenado na variável name e repassado para a variável estadocivil.

import { BasicSelect } from '../../../shared/forms/BasicSelect';

<BasicSelect
  data={[
    { nameBD: 'casado', descricao: 'Casado (a)' },
    { nameBD: 'solteiro', descricao: 'Solteiro (a)' },
    { nameBD: 'divorciado', descricao: 'Divorciado (a)' },
  ]}
  label='Estado Civil'
  name='estadocivil'
/>
                
*/
 

import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useField } from '@unform/core';
import { useEffect } from 'react';

interface IDadosSelect {
  nameBD: string;
  descricao: string;
}

interface BasicSelectProps {
  data: IDadosSelect[];
  label: string;
  name: string;
  disabled: boolean;
  aoClicar?: (novoTextoselect: string | undefined) => void;
}

export const BasicSelect: React.FC<BasicSelectProps> = (props) => {
  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    clearError(); 
    setAge(event.target.value as string);
    props.aoClicar?.(event.target.value as string);
  };

  const { fieldName, registerField, error, clearError } = useField(props.name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => age,
      setValue: (_, newValue) => setAge(newValue)
    });
  }, [registerField, fieldName, age]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={!!error}>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          size='small'
          value={age}
          label={props.label}
          onChange={handleChange}
          disabled={props.disabled}
        >
          {props.data.map((item) => (
            <MenuItem key={item.nameBD} value={item.nameBD}>
              {item.descricao}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && <div style={{ fontSize: 14,color: '#D32F2F' }}>{error}</div>} 
    </Box>
  );
};
