import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FerramentasDaListagemOS } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemOS';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import { IListagemOS, OsService } from '../../../shared/services/api/estoquefazenda/os/OsService';
import dayjs from 'dayjs';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
  faz: string;
  loc: string;
}

export const ListagemDeOS: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();

  const [rows, setRows] = useState<IListagemOS[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'abertas';
  }, [searchParams]);

  const filter = useMemo(() => {
    return searchParams.get('filter') || '';
  }, [searchParams]);

  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  const [busca, setBusca] = useState(true);
  const [data, setData] = useState(true);

  useEffect(() => {
    if (tipo === 'data') {
      setData(true);
      setBusca(false);
    }
    else if (tipo === 'numero') {
      setBusca(true);
      setData(false);
    } else {
      setBusca(false);
      setData(false);
    }
  }, [tipo]);

  useEffect(() => {
    if (tipo === 'abertas' || tipo === 'data' && verificaFormato(dataInicial) && verificaFormato(dataFinal) || tipo === 'numero' && filter != '') {
      setIsLoading(true);

      debounce(() => {
        const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
        if (accessToken) {
          const decoded = jwtDecode<JwtPayload>(accessToken);
          OsService.getAll(pagina, tipo, filter, dataInicial, dataFinal, decoded.faz)
            .then((result) => {
              setIsLoading(false);

              if (result instanceof Error) {
                alert(result.message);
              } else {
                setTotalCount(result.totalCount);
                setRows(result.data);
              }
            });
        }
      });
    }
  }, [pagina, filter, tipo, dataInicial, dataFinal]);

  return (
    <LayoutBaseDePagina
      titulo='Listagem de OS'
      barraDeFerramentas={
        <FerramentasDaListagemOS
          mostrarInputBasicSelect
          mostrarInputData={data}
          mostrarInputBusca={busca}
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoDaBusca={filter}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/os/detalhe/novo')}
          aoMudarTextoDeBusca={texto => setSearchParams({ filter: texto, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarDataInicial={datainicial => setSearchParams({ filter: filter, inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarTextoDoSelect={textoselect => setSearchParams({ filter: filter, inicial: dataInicial, final: dataFinal, tipo: textoselect, pagina: '1' }, { replace: true })}
          aoMudarDataFinal={datafinal => setSearchParams({ filter: filter, inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
          dadosSelecao={[
            { nameBD: 'abertas', descricao: 'Aberta e Aguardando Peças' },
            { nameBD: 'data', descricao: 'Data' },
            { nameBD: 'numero', descricao: 'Número' },
          ]}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Nº OS</TableCell>
              <TableCell>Data da Abertura</TableCell>
              <TableCell>Nº Patrimônio</TableCell>
              <TableCell>Patrimônio</TableCell>
              <TableCell>Modelo</TableCell>
              <TableCell>Situação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idos}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/os/detalhe/${row.idos}`)}
              >
                <TableCell>{row.numero}</TableCell>
                <TableCell>{dayjs(row.dataabertura).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.patrimonio}</TableCell>
                <TableCell>{row.nomepatrimonio}</TableCell>
                <TableCell>{row.modelo}</TableCell>
                <TableCell>{row.situacao}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ filter: filter, inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};