
import { Box, Grid, Paper,Skeleton, Typography } from '@mui/material';
import OutlinedCard2 from '../../../shared/forms/OutlinedCard2';
import { useEffect, useState } from 'react';
import { DashboardEstoqueService } from '../../../shared/services/api/estoquefazenda/dashboard/DashboardEstoqueService'; 
import { LayoutBaseDeDashboard } from '../../../shared/layouts';
import GraficoColuna from './GraficoColuna';
import { getTemaSistema } from '../../../shared/contexts';
import GraficoArea from './GraficoArea';


export const DashboardEstoque = () => { 
  const teme = getTemaSistema();
  const [tema, setTema] = useState<'dark' | 'light'>('light');
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (teme === 'dark') {
      setTema('dark');
    } else {
      setTema('light');
    }
  }, [teme]); 
  const [Server, setServer] = useState(
    {
      nivel: 0,
      estoque:0,
      vencer:0,
      vencidos:0,
      topdez: [],
      entrada: [],
      saida: [],
    }
  );

  useEffect(() => {
    DashboardEstoqueService.GetDashboardEstoqueService()
      .then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setServer(result);
        }
      });
  },[]); 
  type Extrair = {
    titulo: string;
    valor: number;
  };

  function extrairNomes(topdez: Extrair[] | undefined): string[] {
    return topdez ? topdez.map(topdez => topdez.titulo) : [];
  }
  
  function extrairValores(topdez: Extrair[] | undefined): number[] {
    return topdez ? topdez.map(topdez => topdez.valor) : [];
  }

  type ExtrairValor = {
    valor: number;
  };
  function extrairValores2(titulo: ExtrairValor[]): number[] {
    return titulo ? titulo.map(titulo => titulo.valor) : [];
  }

  const produtosmaisusados = [
    {
      name: 'Quantidade',
      data: extrairValores(Server.topdez)
    }
  ];
  const optionsestoque = {
    title: {
      text: 'Top 10 Produtos mais usados ',
    },
    colors:['#00E396', '#FF4560'],
    dataLabels: {
      enabled: false,
    },
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories: extrairNomes(Server.topdez)
    },
    
  };

  const seriesDataRomaneiosAbertos = [
    {
      name: 'Saida',
      data: extrairValores2(Server.saida)
    
    },
    {
      name: 'Entrada',
      data: extrairValores2(Server.entrada)
    
    }
  ];

  const optionsDataRomaneiosAbertos = {
    title: {
      text: 'Quantidade de Entrada e Saida',
    },
    colors:['#DEED82','#00E396'],
    dataLabels: {
      enabled: false,
    },
    
    theme: {
      mode: tema,
    },
    xaxis: { 
      categories:['Janeirio', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    }
  };


  return (
    <LayoutBaseDeDashboard
    >
      <Box display="flex" component={Paper} variant="outlined" gap={1} >
        <Grid container item direction="row"  >
          {(Server.nivel === 2) && (
            <>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <OutlinedCard2
                  nome=" Estoque" 
                  valor={Server.estoque} 
                  cor="#82D2ED"
                  img='/img/emestoque.png'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <OutlinedCard2 
                  nome="A Vencer" 
                  valor={Server.vencer} 
                  cor="#DEED82"
                  img='/img/avencer.png'
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={4} xl={4}>
                <OutlinedCard2 
                  nome="Vencidos" 
                  valor={Server.vencidos} 
                  cor="#FF7F7F"
                  img='/img/datavencimento.png'
                />
              </Grid>
            </>
          )}        
        </Grid>
      </Box >

      <Box display="flex" component={Paper} variant="outlined">

        <Grid container item direction="row" >
          {(Server.nivel === 1) && (
            <Grid container style={{ height: '95vh' }}>
              <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography sx={{ fontSize: 150, color: '#757575', fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} gutterBottom component="div">
                  Estoque
                </Typography>
              </Grid>
            </Grid>
          )}
          {(Server.nivel === 2) && (
            <>
              <Grid  component={Paper} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box margin={0.5}>
                  <Paper elevation={5}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoArea
                        series={seriesDataRomaneiosAbertos}
                        options={optionsDataRomaneiosAbertos} />
                    }
                  </Paper>
                </Box>
              </Grid>

              <Grid component={Paper} item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box margin={0.5}>
                  <Paper elevation={5}>
                    {loading ? <Skeleton variant="rectangular" height={260}/> : 
                      <GraficoColuna
                        series={ produtosmaisusados} 
                        options={optionsestoque}
                      />
                    }
                  </Paper>
                </Box>
              </Grid>              
            </>
          )}
        </Grid>
      </Box>
       
              
      
    </LayoutBaseDeDashboard>
  );
};