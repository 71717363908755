import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import NavTabsListagensDePonto from './NavTabsListagensDePonto';
import { IDetalheRmes, IListagemRmes, RmesService } from '../../../shared/services/api/fazenda/rdiario.mes/RmesService';
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'
import { FerramentasDaListagemPeriodo } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemPeriodo';

dayjs.locale('pt-br'); 

export const ListagemRMes: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const [rowss, setRowss] = useState<IListagemRmes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [idSetected, setIdSetected] = useState('');
  const [pontoData, setPontoData] = useState<IDetalheRmes[]>([]);
  
  const busca = useMemo(() => {
    return searchParams.get('busca') || `${dayjs().format('MMMM')}/${dayjs().year()}`;
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]); 

  useEffect(() => {
    setIsLoading(true);
  
    debounce(() => {
      RmesService.getAll(pagina, busca)
        .then((result) => {
          setIsLoading(false);
  
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setTotalCount(result.totalCount);
            setRowss(result.data);
          }
        });
    });
  }, [busca, pagina]);

  function createData(
    idfun: string,
    nome: string,
    presenca: number,
    falta: number,
    atestado: number,
    domingo: number,
    horaextra: number,
    pistola: number,
    bomba: number,
    cafe: number,
    almoco: number,
    janta: number,
  ) {
    return {
      idfun,
      nome,
      presenca,
      falta,
      atestado,
      domingo,
      horaextra,
      pistola,
      bomba,
      cafe,
      almoco,
      janta,
    };
  }

  const [getid, setGetid] = React.useState(false);

  useEffect(()=>{
  if((idSetected !== null || undefined) && getid === true){
    RmesService.getById(1, idSetected, busca)
    .then((result) => {
      if (result instanceof Error) {
        alert(result.message);
      }else{
        setPontoData(result.data);
      }
    })
  }
}, [idSetected, getid])

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props; 
    const [open, setOpen] = React.useState(false);
    return (
      <React.Fragment>
        <TableRow  sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {setOpen(!open); setGetid(!open); setIdSetected(row.idfun)}}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.nome}
          </TableCell>
          <TableCell align="center">{row.presenca}</TableCell>
          <TableCell align="center">{row.falta}</TableCell>
          <TableCell align="center">{row.atestado}</TableCell>
          <TableCell align="center">{row.domingo}</TableCell>
          <TableCell align="center">{row.horaextra}</TableCell>
          <TableCell align="center">{row.bomba}</TableCell>
          <TableCell align="center">{row.pistola}</TableCell>
          <TableCell align="center">{row.cafe}</TableCell>
          <TableCell align="center">{row.almoco}</TableCell>
          <TableCell align="center">{row.janta}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, width: '100%' }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Ponto por Data
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="center">Ponto</TableCell>
                      <TableCell align="center">Bomba</TableCell>
                      <TableCell align="center">Pistola</TableCell>
                      <TableCell align="center">Café</TableCell>
                      <TableCell align="center">Almoço</TableCell>
                      <TableCell align="center">Janta</TableCell>
                      <TableCell align="center">Horas Extra</TableCell>
                      <TableCell align="left">Serviço Prestado</TableCell>
                      <TableCell align="left">Fazenda</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pontoData.map((pontodataRow) => (
                      <TableRow key={pontodataRow.ponto}>
                        <TableCell align="center" component="th" scope="row">{dayjs(pontodataRow.datalan).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="center">{pontodataRow.ponto}</TableCell>
                        <TableCell align="center">{pontodataRow.bomba}</TableCell>
                        <TableCell align="center">{pontodataRow.pistola}</TableCell>
                        <TableCell align="center">{pontodataRow.cafe}</TableCell>
                        <TableCell align="center">{pontodataRow.almoco}</TableCell>
                        <TableCell align="center">{pontodataRow.janta}</TableCell>
                        <TableCell align="center">{pontodataRow.horaextra}</TableCell>
                        <TableCell>{pontodataRow.historico}</TableCell>
                        <TableCell>{pontodataRow.fazenda}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = useMemo(() => {
    return rowss.map(row => createData(
      row.idfun,
      row.nome,
      row.presenca,
      row.falta,
      row.atestado,
      row.domingo,
      row.horaextra,
      row.pistola,
      row.bomba,
      row.cafe,
      row.almoco,
      row.janta
    ));
  }, [rowss]);  
  
  return (
    <LayoutBaseDePagina
      titulo='Listagem de Ponto Mensal'
      barraDeFerramentas={
        <FerramentasDaListagemPeriodo
          mostrarBotaoNovo={false}
          mostrarInputBusca
          textoDaBusca={busca}
          aoMudarTextoDeBusca={texto => setSearchParams({ busca: texto, pagina: '1' }, { replace: true })}

        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <NavTabsListagensDePonto
          case={1}  
        />
        <Table size='small' aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Colaborador</TableCell>
              <TableCell align="center">Presenças</TableCell>
              <TableCell align="center">Faltas</TableCell>
              <TableCell align="center">Atestado</TableCell>
              <TableCell align="center">Domingo</TableCell>
              <TableCell align="center">Horas Extra</TableCell>
              <TableCell align="center">Bomba</TableCell>
              <TableCell align="center">Pistola</TableCell>
              <TableCell align="center">Café</TableCell>
              <TableCell align="center">Almoço</TableCell>
              <TableCell align="center">Janta</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <Row key={row.nome} row={row} />
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}
          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ busca, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};