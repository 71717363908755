import { Api } from '../../axios-config';

export interface IDetalheHoraExtra {
  idfun: string;
  historico: string;
  datalan: Date;
  horaextra: number;
}

const create = async (dados: IDetalheHoraExtra): Promise<void | Error> => {
  try {
    await Api.post('/pontoshoraextra', dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const HoraExtraService = {
  create,
};