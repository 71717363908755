import { useEffect, useMemo, useState } from 'react';
import { LinearProgress, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { useDebounce } from '../../../shared/hooks';
import { Environment } from '../../../shared/environment';
import dayjs from 'dayjs';
import { IListagemSaidas, SaidaService } from '../../../shared/services/api/estoquefazenda/saida/SaidaService';
import { FerramentasDaListagemSaida } from '../../../shared/components/ferramentas-da-listagem/FerramentasDaListagemSaida';
import { jwtDecode } from 'jwt-decode';

interface JwtPayload {
  faz: string;
  loc: string;
}

export const ListagemDeSaida: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IListagemSaidas[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);

  const tipo = useMemo(() => {
    return searchParams.get('tipo') || 'fazenda';
  }, [searchParams]);

  const dataInicial = useMemo(() => {
    return searchParams.get('inicial') || dayjs().startOf('month').format('DD/MM/YYYY');
  }, [searchParams]);

  const dataFinal = useMemo(() => {
    return searchParams.get('final') || dayjs().format('DD/MM/YYYY').toString();
  }, [searchParams]);

  const pagina = useMemo(() => {
    return Number(searchParams.get('pagina') || '1');
  }, [searchParams]);

  function verificaFormato(data: string) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(data);
  }

  useEffect(() => {
      setIsLoading(true);
        const accessToken = localStorage.getItem('APP_ACCESS_TOKEN');
        if (accessToken) {
          const decoded = jwtDecode<JwtPayload>(accessToken);
          SaidaService.getAll(pagina, dataInicial, dataFinal, 'fazendadata', decoded.faz)
            .then((result) => {
              setIsLoading(false);
              if (result instanceof Error) {
                alert(result.message);
              } else {
                setTotalCount(result.totalCount);
                setRows(result.data);
              }
            });
        }
  }, [dataInicial, dataFinal, pagina, tipo]);

  return (
    <LayoutBaseDePagina
      titulo='Listagem de Saídas'
      barraDeFerramentas={
        <FerramentasDaListagemSaida
          mostrarInputData
          dataInicial={dataInicial}
          dataFinal={dataFinal}
          textoDoSelect={tipo}
          textoBotaoNovo='Adicionar'
          aoClicarEmNovo={() => navigate('/saidaestoque/detalhe/novo')}
          aoMudarDataInicial={datainicial => setSearchParams({ inicial: datainicial, final: dataFinal, tipo: tipo, pagina: '1' }, { replace: true })}
          aoMudarDataFinal={datafinal => setSearchParams({ inicial: dataInicial, final: datafinal, tipo: tipo, pagina: '1' }, { replace: true })}
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Data</TableCell>
              <TableCell>Fazenda</TableCell>
              <TableCell>Operação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.idsaida}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/saidaestoque/detalhe/${row.idsaida}`)}
              >
                <TableCell>{dayjs(row.dataoperacao).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{row.fazenda}</TableCell>
                <TableCell>{row.operacoes}</TableCell>
              </TableRow>
            ))}
          </TableBody>

          {totalCount === 0 && !isLoading && (
            <caption>{Environment.LISTAGEM_VAZIA}</caption>
          )}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant='indeterminate' />
                </TableCell>
              </TableRow>
            )}
            {(totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={pagina}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ inicial: dataInicial, final: dataFinal, tipo: tipo, pagina: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </LayoutBaseDePagina>
  );
};