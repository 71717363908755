import { Api } from '../../axios-config';

export interface IDetalheHoraExtra {
  idfun: string;
  datainicial: Date;
  quantidade: number;
}

const create = async (dados: IDetalheHoraExtra): Promise<void | Error> => {
  try {
    await Api.post('/pontosatestado', dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const AtestadoService = {
  create,
};