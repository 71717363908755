import { Environment } from '../../../../environment';
import { v4 as uuidv4 } from 'uuid';
import { Apicadastro } from '../../axioscadastro';

export interface IListagemPivotFazenda {
  idpivot: string;
  idfazenda: string;
  descricao: string;
  hectare: number;
  status: boolean;
}

export interface IDetalhePivotFazenda {
  idfazenda: string;
  descricao: string;
  hectare: number;
  status: boolean;
}

type TotalCount = {
    data: IListagemPivotFazenda[]; 
    totalCountPivot: number;
}

const getAlllist = async (idfazenda = '', filter = '', id = ''): Promise<TotalCount | Error> => {
  //console.log(idfazenda);
  try {
    const urlRelativa: string = id === '' ?  `/pivotslist?limit=${Environment.LIMITE_DE_LINHAS}&fazenda=${idfazenda}&filter=${filter}` : `/pivotslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCountPivot: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAlllistPivots  = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {
    const urlRelativa: string = id === '' ?  `/pivotsfazendalist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/pivotslist/${id}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCountPivot: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getAll = async (page = 1, id: string): Promise<TotalCount | Error> => {

  try {
    const urlRelativa = `/pivots?idfazenda=${id}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await Apicadastro.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCountPivot: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: string): Promise<IDetalhePivotFazenda | Error> => {
  try {
    const { data } = await Apicadastro.get(`/pivots/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IDetalhePivotFazenda): Promise<string | Error> => {
  try {
    const guuid = uuidv4();

    const { data } = await Apicadastro.post<IDetalhePivotFazenda>('/pivots',  {idpivot: guuid, ...dados});
  
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: string, dados: IDetalhePivotFazenda): Promise<void | Error> => {
  try {
    await Apicadastro.put(`/pivots/${id}`, dados);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const deleteById = async (id: string): Promise<void | Error> => {
  try {
    await Apicadastro.delete(`/pivots/${id}`);
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const PivotService = {
  getAll,
  getAlllist,
  create,
  getById,
  updateById,
  deleteById,
  getAlllistPivots,
};