export const Environment = {
  /**
     * Define a quantidade de linhas a ser carregada por padrão nas listagens
     */
  LIMITE_DE_LINHAS: 8,
  LIMITE_DE_LINHASSMALL: 11,
  LIMITE_DE_CADASTRO: 10,
  /**
     * Placeholder exibido nas inputs
     */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
     * Texto exibido quando nenhum registro é encontrado em uma listagem
     */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
  /**
     * Url base de consultado dos dados dessa aplicação
     */

   URL_BASE: process.env.REACT_APP_URL_BASE,
   URL_BASECADASTRO: process.env.REACT_APP_URL_BASECADASTRO,
   URL_IMGFUNCIONARIO: process.env.REACT_APP_URL_IMGFUNCIONARIO,
   URL_IMGUSUARIOS: process.env.REACT_APP_URL_IMGUSUARIOS,
   URL_BASEESTOQUE: process.env.REACT_APP_URL_BASEESTOQUE,
   URL_IMGPATRIMONIO: process.env.REACT_APP_URL_IMGPATRIMONIO,

};



