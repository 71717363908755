
import { v4 as uuidv4 } from 'uuid';
import { Apiestoque } from '../../estoque/axiosestoque';

export interface IDetalheAbastecimento  {
  idpatrimonio:string,
  idprod:string,
  dataoperacao: Date,
  idcultivo?: string | null;
  idpivot?: string | null;
  idatividade: string,
  horimetro: number,
  horimetronicial: number,
  quantidade: number,
  preco:number,
  total:number,
  operador: string,
  horastrabalhadas: number,
  litros: number,
  kmhr: string,
  quantidadeestoque: number,
}

const create = async (dados: IDetalheAbastecimento): Promise<string | Error> => {
  try {
    const guuid = uuidv4();
    const { quantidadeestoque, kmhr, litros, ...dadosIDetalheAbastecimento } = dados;
    const { data } = await Apiestoque.post<IDetalheAbastecimento>('/abastecimento',  {idabastecimento: guuid, ...dadosIDetalheAbastecimento});
    
    if (Object.keys(data).length === 0) {
      return guuid;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const Abateciemento = {
  create,
};