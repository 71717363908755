import { Environment } from '../../../../environment';
import { Api } from '../../axios-config';

export interface IListagemColaborador {
  idfun: string;
  nome: string;
  apelido: string;
  cpf: string;
  urlimg: string;
}

type TotalCount = {
    data: IListagemColaborador[];
    totalCount: number;
}

const getAllList = async (filter = '', id = ''): Promise<TotalCount | Error> => {
  try {

    const urlRelativa: string = id === '' ? `/funcionarioslist?limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}` : `/funcionarioslist/${id}`;

    const { data, headers } = await Api.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
 
    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const ColaboradorService = {
  getAllList,
};